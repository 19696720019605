import { useMemo } from 'preact/hooks';
import { DefaultContextIntent } from '../DefaultContext';
import { IConfig } from '..';
import { useGlobalContext } from './use-global-context';

interface ParseHashReturnValue {
  rph_init?: RphInit;
  rph_invite?: RphInvite;
}

enum RphInitErrorType {
  Google = 'google',
  Apple = 'apple',
  OAuth2 = 'oauth2',
}

interface RphInitError {
  type: RphInitErrorType;
  message: string;
  status_code: number;
  code?: string;
}

interface RphInit {
  error?: RphInitError;
  intent?: DefaultContextIntent;
  token?: string;
  user_type?: string;
  user_data?: Record<string, any>;
  access_token?: string;
  refresh_token?: string;
  app_id?: string;
  app_user_id?: string;
  last_sign_in?: string;
  last_sign_in_date?: string;
  reset_post_sign_in_reqs?: boolean;
}

export interface RphInvite {
  id: string;
  app_id: string;
  group_id: string;
}

function useRphHash(): ParseHashReturnValue | undefined {
  const { state } = useGlobalContext();

  const rphHash = useMemo(() => {
    if (!state.config?.locationHash) {
      return undefined;
    }
    return parseHash(state.config?.locationHash);
  }, [state.config?.locationHash]);

  return rphHash;
}

function parseHash(hash: string): ParseHashReturnValue {
  const re = RegExp(/(?<rph_key>rph_[\w]+)=(?<rph_value>[\w\d\-=.]+)/g);
  const hashMatches = [...hash.matchAll(re)];
  return hashMatches.reduce((acc, match) => {
    const key = match?.groups?.rph_key;
    const value = match?.groups?.rph_value;
    if (!(key && value)) {
      return acc;
    }

    const decodedValue = atob(value).toString();

    if (key.startsWith('rph_init')) {
      const parsedValue = JSON.parse(decodedValue) as ParseHashReturnValue['rph_init'];
      acc.rph_init = parsedValue;
      return acc;
    }
    if (key.startsWith('rph_invite')) {
      const parsedValue = JSON.parse(decodedValue) as ParseHashReturnValue['rph_invite'];
      acc.rph_invite = parsedValue;
      return acc;
    }
    return acc;
  }, {} as ParseHashReturnValue);
}

export default useRphHash;
