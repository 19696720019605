export type Automation = {
  id: string;
  name: string;
  rules: Rule[];
  triggers: Trigger[];
  actions?: Action[];
  state: 'enabled' | 'disabled';
  platform: string;
};

export type AutomationRule = {
  entity_type: EntityType;
  attribute: string;
  condition: Condition;
  value?: string;
};

export function isRule(rule: AutomationOrRule | AutomationRule): rule is AutomationRule {
  return (<AutomationRule>rule).entity_type !== undefined;
}

export type AutomationOrRule = {
  $or: Rule[];
};

export type Rule = AutomationRule | AutomationOrRule;

export enum AutomationRuleType {
  AND,
  OR,
}

export enum EntityType {
  USER_DATA = 'USER_DATA',
  METADATA = 'METADATA',
  SCOPE = 'SCOPE',
}

export enum Condition {
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  EXISTS = 'EXISTS',
  NOT_EXISTS = 'NOT_EXISTS',
  DATE_IS_BEFORE = 'DATE_IS_BEFORE',
  DATE_IS_AFTER = 'DATE_IS_AFTER',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUAL = 'GREATER_THAN_EQUAL',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUAL = 'LESS_THAN_EQUAL',
}

export type Trigger = {
  type: TriggerType;
  value: string;
  target?: string;
};

export enum TriggerType {
  URL = 'URL',
  HTML_SELECTOR = 'HTML_SELECTOR',
  HTML_SELECTOR_VISIBLE = 'HTML_SELECTOR_VISIBLE',
  EVENT = 'EVENT',
  TIME = 'TIME',
  TIME_ONCE = 'TIME_ONCE',
  POST_SIGN_IN = 'POST_SIGN_IN',
}

export enum EditContentChildrenType {
  Blur = 'blur',
  Button = 'button',
}

export type EditContentStyle = Record<string, string>;

export type EditContentChildren = {
  type?: EditContentChildrenType;
  element: string;
  action?: Action;
  innerHTML: string;
  style?: EditContentStyle;
  children?: EditContentChildren;
};

export type EditContentAction = {
  type: ActionType.EDIT_ELEMENTS;
  args?: {
    style: EditContentStyle;
    selector: string;
    children?: EditContentChildren[];
  };
};

export type Action = {
  type: ActionType;
  args?: ActionArgs;
};

export type ActionArgs = Record<string, any>;

export type ActionFunction = (args?: ActionArgs) => void;

export enum ActionType {
  NONE = 'NONE',
  REQUIRE_VERIFICATION = 'REQUIRE_VERIFICATION',
  REQUIRE_AUTHENTICATION = 'REQUIRE_AUTHENTICATION',
  PROMPT_FOR_INPUT = 'PROMPT_FOR_INPUT',
  REDIRECT = 'REDIRECT',
  PROMPT_FOR_PASSKEY = 'PROMPT_FOR_PASSKEY',
  COLLECT_INFORMATION = 'COLLECT_INFORMATION',
  LEGAL_OPT_IN = 'LEGAL_OPT_IN',
  SIGN_OUT = 'SIGN_OUT',
  OPEN_PROFILE = 'OPEN_PROFILE',
  EDIT_ELEMENTS = 'EDIT_ELEMENTS',
}

export interface UserMetadata {
  is_authenticated?: boolean;
  is_verified?: boolean;
  [key: string]: any;
}
