import { h, FunctionComponent } from 'preact';
import { useCallback, useEffect, useReducer, useRef } from 'preact/hooks';
import isDeepEqual from 'lodash-es/isEqual';
import cloneDeep from 'lodash-es/cloneDeep';
import merge from 'lodash-es/merge';
import _set from 'lodash-es/set';
import jwt_decode from 'jwt-decode';
import { IConfig } from './index';
import ExternalApi from './ExternalApi';
import TimerLoop from './TimerLoop';
import { events, EventType } from './events';
import { detectColorMode, determineSignInMethodBasedOnPriority, isPageActive, removeUndefinedKeysDeep } from './utils';
import { logger } from './utils/log';
import { storage } from './storage';
import ExternalDomController from './ExternalDomController';
import DefaultContext from './DefaultContext';
import { ActionType, GlobalContext, GlobalState, SignInMethods, TAction, User } from './hooks/use-global-context';
import { sendMessageToApp, MessageType } from './utils/mobile-app';
import { TokenClaims, isTokenExpired } from './utils/token';
import { DimensionProvider } from './hooks/use-dimensions-context';

type ContextProps = {
  config: IConfig;
};

function updateStorage(newState: any) {
  const oldState = JSON.parse(storage.getItem('state') || '{}');

  if (!isDeepEqual(oldState, newState)) {
    storage.setItem('state', JSON.stringify(newState));
  }
}

const Context: FunctionComponent<ContextProps> = ({ children, config }) => {
  // eslint-disable-next-line no-undef
  const timerInitialization = useRef<NodeJS.Timeout | undefined>(undefined);
  const initialStateUser: User = {
    data: {},
    meta: {},
    groups: [],
    needs_refresh: false,
    loaded_once: false,
    redacted: [],
    verified_data: {},
    passkeys: {
      is_initialized: false,
      registrations: [],
    },
    is_loading: false,
  };

  const initialNavState = {
    current_route: '',
    route_trigger: '',
    event_id: '',
    section: '',
    options: {},
    step: undefined,
  };

  const initialState: GlobalState = {
    is_initializing: true,
    is_container_visible: false,
    use_modal: false,
    computed_color_mode: 'light',
    sign_in: {},
    nav: initialNavState,
    user: initialStateUser,
    auth: {
      access_token: null,
      refresh_token: null,
      app_id: null,
    },
    app: {
      schema: null,
      config: null,
    },
    is_saving_user_data: false,
    is_loading_user_data: false,
    is_waiting_for_post_authentication_api: false,
    is_accepting_group_invite: false,
    is_post_sign_in_requirements_done: false,
    config,
  };

  // TODO: There's a better way to do this where we have a set of rules that watch/apply to the state as it changes
  function dispatchExternalEvents(prevState: GlobalState, nextState: GlobalState, action: TAction | null = null) {
    // Log in, get new token, load previous auth state, etc
    if (
      prevState.auth.access_token !== nextState.auth.access_token &&
      nextState.auth.access_token !== null &&
      !isTokenExpired(nextState.auth.access_token)
    ) {
      events.dispatch(EventType.AUTH, {
        access_token: nextState.auth.access_token,
        user_id: nextState.user.data.id,
        app_id: nextState.auth.app_id,
      });

      // More detailed message for SDK consumers
      sendMessageToApp({
        type: MessageType.AUTHENTICATION,
        payload: {
          access_token: nextState.auth.access_token!,
          refresh_token: nextState.auth.refresh_token!,
        },
      });
    }

    // User data changes.
    if (!isDeepEqual(prevState.user.data, nextState.user.data)) {
      events.dispatch(EventType.USER_DATA, {
        data: nextState.user.data,
      });
    }

    // Log out
    if (
      action?.type == ActionType.SIGN_OUT ||
      (prevState.auth.access_token !== null && nextState.auth.access_token === null)
    ) {
      events.dispatch(EventType.SIGN_OUT, {});
      sendMessageToApp({
        type: MessageType.SIGN_OUT,
      });
    }
  }

  function computeInitialConfig(config: IConfig) {
    if (config.appleIdCallbackUrl === null) {
      config.appleIdCallbackUrl = `${config.apiUrl}/hub/auth/apple/callback`;
    }

    if (config.googleIdCallbackUrl === null) {
      config.googleIdCallbackUrl = `${config.apiUrl}/hub/auth/google/callback`;
    }

    if (config.oauth2AuthorizeUrl === null) {
      config.oauth2AuthorizeUrl = `${config.apiUrl}/hub/auth/oauth2/{provider}/authorize`;
    }
  }

  function initState(state: GlobalState): GlobalState {
    const existingStateStr = storage.getItem('state');

    computeInitialConfig(config);

    if (!existingStateStr) {
      state.config = config;
      return state;
    }

    const existingState: GlobalState = JSON.parse(existingStateStr);
    existingState.config = config;

    // Reset specific parts of the state tree for use with mobile SDKs
    if (config?.displayContext === 'mobile_app') {
      existingState.user = initialStateUser;
      existingState.auth = {
        access_token: null,
        refresh_token: null,
        app_id: null,
      };
    }

    dispatchExternalEvents(state, existingState);

    return merge(state, existingState);
  }

  function mainReducer(state: GlobalState, action: TAction): GlobalState {
    logger.log('state change:', action);

    const computed_color_mode = detectColorMode();
    let decodedToken: any;

    let newState: GlobalState;
    switch (action.type) {
      case ActionType.SET_CONTAINER_VISIBLE:
        newState = {
          ...state,
          is_container_visible: action.payload.isVisible,
          use_modal: action.payload.isVisible === false ? false : state.use_modal,
          nav: action.payload.isVisible === false ? initialState.nav : state.nav,
          automations: {
            ...state.automations,
            active_automation: action.payload.isVisible ? state.automations?.active_automation : undefined,
            queue: action.payload.isVisible ? state.automations?.queue : [],
          },
        };

        if (!newState.is_container_visible && state.config?.displayContext === 'mobile_app') {
          sendMessageToApp({ type: MessageType.CLOSE_HUB_VIEW_CONTROLLER });
        }

        break;

      case ActionType.CHANGE_ROUTE:
        newState = {
          ...state,
          use_modal: action.payload.opts?.use_modal === true,
          is_container_visible: action.payload.opts?.is_container_visible !== false,
          nav: {
            ...state.nav,
            current_route: action.payload.route,
            route_trigger: action.payload.trigger || '',
            event_id: action.payload.event_id,
            options: action.payload.opts,
          },
        };

        break;

      case ActionType.SET_USE_MODAL:
        newState = {
          ...state,
          use_modal: action.payload,
        };
        break;

      case ActionType.SET_POPUP_ROUTE:
        newState = {
          ...state,
          nav: {
            ...state.nav,
            popup_route: action.payload.popup_route,
          },
        };

        break;

      case ActionType.SET_HUB_STEP:
        newState = {
          ...state,
          nav: {
            ...state.nav,
            step: action.payload,
          },
        };
        break;

      case ActionType.SET_SECTION:
        newState = {
          ...state,
          nav: {
            ...state.nav,
            section: action.payload.section,
          },
        };
        break;

      case ActionType.LOGIN_SUCCESS: {
        try {
          decodedToken = jwt_decode(action.payload.access_token);
        } catch (err) {
          logger.error(`Error decoding token ${action.payload.access_token} during ActionType.LOGIN_SUCCESS`, err);
        }
        const newUserData =
          state.user.data?.id && state.user.data?.id === action.payload.app_user_id
            ? { ...state.user.data }
            : { id: action.payload.app_user_id };
        newState = {
          ...state,
          auth: {
            ...state.auth,
            access_token: action.payload.access_token,
            refresh_token: action.payload.refresh_token,
            app_id: action.payload.app_id,
            auth_level: decodedToken?.[TokenClaims.AuthLevel],
            is_verified_user: decodedToken?.[TokenClaims.IsVerifiedUser] !== false, // default is `true` if the attribute doesn't exist
          },
          user: {
            ...state.user,
            data: newUserData,
            instant_user: {
              ...state.user.instant_user,
              is_initializing: false,
            },
          },
        };
        break;
      }

      case ActionType.SIGN_OUT:
        newState = {
          ...state,
          user: initialStateUser,
          auth: {
            access_token: null,
            refresh_token: null,
            app_id: null,
          },
          is_post_sign_in_requirements_done: false,
        };
        if (action.payload?.show_success === true) {
          newState = {
            ...newState,
            use_modal: true,
            nav: {
              ...state.nav,
              current_route: '/success',
              route_trigger: 'sign_out',
            },
          };
        }
        break;

      case ActionType.REFRESH_TOKEN:
        newState = {
          ...state,
          auth: {
            ...state.auth,
            access_token: action.payload.access_token,
            refresh_token: action.payload.refresh_token,
          },
        };

        break;

      case ActionType.LOAD_USER:
        newState = {
          ...state,
          user: !state.auth?.access_token
            ? initialStateUser
            : {
                ...state.user,
                data: {
                  ...action.payload.data,
                },
                meta: {
                  ...action.payload.meta,
                },
                groups: action.payload.groups,
                redacted: action.payload.redacted,
                verified_data: action.payload.verified_data,
                loaded_once: true,
              },
        };
        break;

      case ActionType.SET_USER_DATA_FIELD:
        newState = {
          ...state,
          user: {
            ...state.user,
            data: {
              ...state.user.data,
              [action.payload.field]: action.payload.value,
            },
          },
        };
        break;

      case ActionType.SET_USER_DATA:
        newState = {
          ...state,
          user: {
            ...state.user,
            data: {
              ...state.user.data,
              ...action.payload.data,
            },
          },
        };
        break;

      case ActionType.SET_REFRESH_USER_DATA:
        newState = {
          ...state,
          user: {
            ...state.user,
            needs_refresh: action.payload.needs_refresh,
          },
        };
        break;

      case ActionType.SET_USER_META:
        newState = {
          ...state,
          user: {
            ...state.user,
            meta: {
              ...action.payload,
            },
          },
        };
        break;

      case ActionType.LOAD_STATE:
        newState = {
          ...initState(state),
        };
        break;

      case ActionType.SYNC_STATE:
        newState = {
          ...state,
          ...{
            app: {
              ...state.app,
              ...action.payload?.app,
              config: {
                ...state.app.config, // Don't allow sync state to overwrite config
              },
            },
            auth: {
              ...action.payload?.auth,
            },
            sign_in: {
              ...state.sign_in,
              ...action.payload?.sign_in,
            },
            user: {
              ...state.user,
              ...action.payload?.user,
            },
          },
        };
        break;

      case ActionType.SET_IS_SAVING_USER_DATA: {
        const isSaving = action.payload.saving;
        newState = {
          ...state,
          is_saving_user_data: isSaving,
          user: {
            ...state.user,
            is_loading: isSaving,
          },
        };
        break;
      }

      case ActionType.SET_IS_LOADING_USER_DATA: {
        const isLoading = action.payload.loading;
        newState = {
          ...state,
          is_loading_user_data: isLoading,
          user: {
            ...state.user,
            is_loading: isLoading,
          },
        };
        break;
      }

      case ActionType.SET_IS_WAITING_FOR_POST_AUTHENTICATION_API:
        newState = {
          ...state,
          is_waiting_for_post_authentication_api: action.payload.waiting,
        };
        break;

      case ActionType.SET_IS_ACCEPTING_GROUP_INVITE:
        newState = {
          ...state,
          is_accepting_group_invite: action.payload.accepting,
        };
        break;

      case ActionType.SET_IS_POST_SIGN_IN_REQUIREMENTS_DONE:
        newState = {
          ...state,
          is_post_sign_in_requirements_done: action.payload,
        };
        break;

      case ActionType.SET_USER_PASSKEYS:
        newState = {
          ...state,
          user: {
            ...state.user,
            passkeys: {
              is_initialized: true,
              registrations: action.payload,
            },
          },
        };
        break;

      case ActionType.SET_APP_CONFIG:
        newState = {
          ...state,
          app: {
            ...state.app,
            ...action.payload,
            invalid_web_origins:
              action?.payload?.config?.hub &&
              Object.keys(action?.payload?.config?.hub).length === 1 &&
              !!action?.payload?.config?.hub?.allowed_web_origins,
          },
        };

        newState.computed_color_mode =
          newState?.app?.config?.hub?.customizations?.dark_mode === 'auto'
            ? computed_color_mode
            : newState?.app?.config?.hub?.customizations?.dark_mode === 'enabled'
            ? 'dark'
            : 'light';

        break;

      case ActionType.SET_COLOR_SCHEME:
        newState = {
          ...state,
          computed_color_mode: action.payload === 'dark' ? 'dark' : 'light',
        };
        break;

      case ActionType.SET_SIGN_IN_METHOD:
        newState = {
          ...state,
          // Don't save Sign In data if Mobile SDK is not setup for it yet.
          sign_in:
            state.config?.displayContext === 'mobile_app' && !new URLSearchParams(window.location.search).get('sign_in')
              ? {
                  ...state?.sign_in,
                }
              : {
                  ...state?.sign_in,
                  last_sign_in: action.payload?.enable_priority
                    ? determineSignInMethodBasedOnPriority({
                        newMethod: action.payload?.last_sign_in as keyof SignInMethods,
                        method: state?.sign_in?.last_sign_in,
                      })
                    : action.payload?.last_sign_in,
                  last_sign_in_date: action.payload?.last_sign_in_date || new Date().toISOString(),
                },
        };
        break;

      case ActionType.SET_AUTOMATION_QUEUE:
        newState = {
          ...state,
          automations: {
            queue: action.payload?.queue ? action.payload.queue : state.automations?.queue,
            active_automation:
              typeof action.payload?.active_automation == 'string'
                ? action.payload?.active_automation
                : state.automations?.active_automation,
          },
        };
        break;

      case ActionType.SET_ANDROID_ACTIVE_ACCOUNTS:
        newState = {
          ...state,
          android: {
            ...state.android,
            active_accounts: action.payload,
          },
        };
        break;

      case ActionType.SET_INSTANT_USER_IS_INITIALIZING:
        newState = {
          ...state,
          user: {
            ...state.user,
            instant_user: {
              is_initializing: Boolean(action.payload),
            },
          },
        };
        break;

      case ActionType.SET_IS_INITIALIZING:
        newState = {
          ...state,
          is_initializing: action.payload,
        };
        break;

      case ActionType.RESET_NAV_STATE: {
        if (!state.use_modal) {
          newState = state;
          break;
        }
        newState = {
          ...state,
          nav: initialNavState,
          use_modal: false,
          automations: undefined,
        };
        break;
      }

      case ActionType.SET_LOCAL_SETTINGS_DARK_MODE: {
        newState = {
          ...state,
          localSettings: {
            ...state?.localSettings,
            customizations: {
              ...state.localSettings?.customizations,
              dark_mode: action.payload,
            },
          },
        };
        break;
      }

      default:
        newState = state;
    }

    // Mobile apps should always use modals
    if (newState?.config?.displayContext === 'mobile_app') {
      newState.use_modal = true;
    }

    function handleIsInitializing() {
      const config = newState.app.config;
      if (!config) {
        return;
      }

      const isInstantUsersEnabled = Boolean(config?.hub?.auth?.instant_user?.enabled);
      if (isInstantUsersEnabled) {
        if (state.auth.access_token) {
          newState.is_initializing = false;
          clearTimeout(timerInitialization.current);
        } else if (!timerInitialization.current) {
          // Set initialization to false after 2secs as a fallback to if createInstantUser is taking too long
          timerInitialization.current = setTimeout(() => {
            if (state.is_initializing) {
              dispatch({ type: ActionType.SET_IS_INITIALIZING, payload: false });
            }
          }, 2000);
        }
        return;
      }

      newState.is_initializing = false;
    }

    handleIsInitializing();

    // Write state to local storage, minus a few fields
    const persistedState: any = cloneDeep(newState);
    delete persistedState.config;
    delete persistedState.nav;
    delete persistedState.is_container_visible;
    delete persistedState.use_modal;
    delete persistedState.is_initializing;
    delete persistedState.is_saving_user_data;
    delete persistedState.user.passkeys.is_initialized;
    delete persistedState.user?.instant_user?.is_initializing;
    delete persistedState.automations;

    updateStorage(persistedState);

    // Fire any events that depend on state updates
    dispatchExternalEvents(state, newState, action);
    syncedStateRef.current = newState;
    return newState;
  }

  const [state, dispatch] = useReducer(mainReducer, initialState, initState);

  const shouldStateSync = useCallback(
    ({ newState, state }: { newState?: GlobalState; state: GlobalState }): boolean => {
      if (!newState) return false;
      const minimalUserData = (user: User) => {
        const minimalUser: User | Record<string, any> = removeUndefinedKeysDeep(cloneDeep(user));
        delete minimalUser?.is_loading;
        delete minimalUser?.loaded_once;
        delete minimalUser?.redacted;
        delete minimalUser?.passkeys?.is_initialized;
        delete minimalUser?.instant_user?.is_initializing;
        return minimalUser;
      };

      // Only check for diffs to auth/user state
      if (
        isDeepEqual(
          {
            ...newState.auth,
            ...minimalUserData(newState.user),
          },
          {
            ...state.auth,
            ...minimalUserData(state.user),
          },
        )
      )
        return false;

      // Don't sync state if valid auth and userId is missing
      if (newState.auth.access_token && !newState.user.data.user_id) return false;
      return true;
    },
    [],
  );

  const syncedStateRef = useRef<GlobalState>(state);
  // Listen to localStorage changes to update client store
  useEffect(() => {
    // Mobile apps don't need local/root state sync -- web apps/sites only
    if (state.config?.displayContext === 'mobile_app') {
      return;
    }

    const syncFromLocalStorage = () => {
      // Prevent local storage updates when page is active
      if (isPageActive()) {
        return;
      }
      const newState: GlobalState | undefined = JSON.parse({ ...window?.localStorage }?.['rph_state'] || '{}');
      if (!newState) return;
      if (!shouldStateSync({ newState, state: syncedStateRef.current })) return;
      syncedStateRef.current = newState;
      dispatch({
        type: ActionType.SYNC_STATE,
        payload: newState,
      });
    };

    window.addEventListener('storage', syncFromLocalStorage);
    return () => {
      window.removeEventListener('storage', syncFromLocalStorage);
    };
  }, [shouldStateSync, state.config?.displayContext]);

  useEffect(() => {
    // Mobile apps don't need local/root state sync -- web apps/sites only
    if (state.config?.displayContext === 'mobile_app') {
      return;
    }

    const handleRootSync = (evt: any) => {
      const rph_state = evt.detail['rph_state'];
      if (rph_state) {
        // Prevent root sync updates when page is active
        if (isPageActive()) {
          return;
        }
        const rootState: GlobalState | undefined = JSON.parse(rph_state);
        if (!rootState) return;
        if (!shouldStateSync({ newState: rootState, state: syncedStateRef.current })) return;
        // Remove app config from root sync state since it may be for a different app variant
        _set(rootState, 'app.config', null);
        syncedStateRef.current = rootState;

        dispatch({
          type: ActionType.SYNC_STATE,
          payload: rootState,
        });
      }
    };

    // Listen to ROOT_SYNC event which updates state from root localStorage
    events.addEventListener(EventType.ROOT_SYNC, handleRootSync, { once: false });
    return () => {
      events.removeEventListener(EventType.ROOT_SYNC, handleRootSync);
    };
  }, [shouldStateSync, state.config?.displayContext]);

  const value = { state, dispatch };

  return (
    <GlobalContext.Provider value={value}>
      <DimensionProvider>
        <DefaultContext config={config} />
        <TimerLoop />
        {children}
        <ExternalApi
          config={config}
          dispatchEvents={(state: GlobalState) => dispatchExternalEvents(initialState, state)}
        />
        <ExternalDomController />
      </DimensionProvider>
    </GlobalContext.Provider>
  );
};

export { Context };
