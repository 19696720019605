import ky, { HTTPError } from 'ky';
import AutoQueue from '../queue';
import { logger } from '../utils/log';
import { useRef, useEffect } from 'preact/hooks';
import { isTokenExpired } from '../utils/token';
import { useGlobalContext, ActionType, GlobalState } from './use-global-context';

type RefreshTokenResp = {
  access_token: string;
  refresh_token: string;
};

const refreshQueue = new AutoQueue<RefreshTokenResp>();

interface IRowndApiError {
  error?: string;
  messages?: string[];
  statusCode?: number;
  code?: string;
}

export enum RowndApiErrorCodes {
  E_RESTRICT_SIGN_UPS = 'E_RESTRICT_SIGN_UPS',
  E_RESTRICT_EMAIL_DOMAIN = 'E_RESTRICT_EMAIL_DOMAIN',
  E_USER_PROFILE_DISABLED = 'E_USER_PROFILE_DISABLED',
}

export class RowndApiError extends HTTPError implements IRowndApiError {
  error?: string;
  messages?: string[];
  statusCode?: number;
  code?: RowndApiErrorCodes | string;
  payload?: unknown;

  static create = async (inError: HTTPError): Promise<RowndApiError> => {
    const outError = new RowndApiError(inError.response, inError.request, inError.options);

    try {
      const body: any = await inError.response?.json();
      outError.payload = body;

      if (Array.isArray(body.messages)) {
        outError.messages = body.messages;
        outError.message = body.messages.join(' ');
      }

      outError.error = body.error;
      outError.statusCode = inError.response?.status || body.statusCode;
      outError.code = body.code;
    } catch (err) {}

    return outError;
  };
}

export default function useApi() {
  const { state, dispatch } = useGlobalContext();

  const authRef = useRef({
    access_token: state.auth.access_token,
    refresh_token: state.auth.refresh_token,
  });

  useEffect(() => {
    authRef.current = {
      access_token: state.auth.access_token,
      refresh_token: state.auth.refresh_token,
    };
  }, [state.auth.access_token, state.auth.refresh_token]);

  function isNewAccessTokenNeeded(request?: Request) {
    // Skip requests that don't need authentication
    if ((!!request && !request?.headers.get('authorization')) || !authRef.current?.access_token) {
      return false;
    }

    return isTokenExpired(authRef.current?.access_token);
  }

  async function _newAccessTokenFromRefreshToken(this: AutoQueue<RefreshTokenResp>, stateCopy?: GlobalState) {
    stateCopy = stateCopy || state;
    if (this?._cache?.resp) {
      logger.log('using cached refresh response');
      return this._cache.resp;
    }

    try {
      logger.log('requesting new refresh token');
      const resp: RefreshTokenResp = await ky
        .post(`${stateCopy.config?.apiUrl}/hub/auth/token`, {
          json: {
            refresh_token: stateCopy.auth?.refresh_token,
          },
        })
        .json();

      this._cache.resp = resp;

      // Update local cache ref immediately to prevent stale auth checks
      authRef.current = {
        access_token: resp.access_token,
        refresh_token: resp.refresh_token,
      };

      dispatch({
        type: ActionType.REFRESH_TOKEN,
        payload: resp,
      });

      return resp;
    } catch (err) {
      // Only sign the user out if the API response is a 400
      if ((err as HTTPError).name === 'HTTPError' && (err as HTTPError).response?.status === 400) {
        dispatch({
          type: ActionType.SIGN_OUT,
        });

        throw err;
      }

      logger.error('Error refreshing token', err);
    }
  }

  async function newAccessTokenFromRefreshToken(stateCopy?: GlobalState): Promise<RefreshTokenResp> {
    return await refreshQueue.enqueue(_newAccessTokenFromRefreshToken.bind(refreshQueue, stateCopy));
  }

  const client = useRef(
    ky.extend({
      prefixUrl: state.config?.apiUrl,
      headers: {
        'Content-Type': 'application/json',
      },
      retry: {
        limit: 2,
        statusCodes: [401, 408, 429, 500, 502, 503, 504],
      },
      hooks: {
        beforeRequest: [
          // Auto-refresh tokens
          async (request, options) => {
            if (options.authenticated) {
              if (!authRef.current.access_token) {
                throw new Error('Authenticated request attempted while signed out');
              }
              request.headers.set('Authorization', `Bearer ${authRef.current.access_token}`);
            }

            // Skip requests that don't need authentication
            if (!isNewAccessTokenNeeded(request)) {
              return;
            }

            const tokenResp: RefreshTokenResp = await newAccessTokenFromRefreshToken();

            request.headers.set('Authorization', `Bearer ${tokenResp.access_token}`);
          },
        ],
        beforeRetry: [
          async ({ request }) => {
            // Skip requests that don't need authentication
            if (!isNewAccessTokenNeeded(request)) {
              return;
            }

            const tokenResp: RefreshTokenResp = await newAccessTokenFromRefreshToken();

            request.headers.set('Authorization', `Bearer ${tokenResp.access_token}`);
          },
        ],
        beforeError: [
          async (error) => {
            return RowndApiError.create(error);
          },
        ],
      },
    }),
  ).current;

  return {
    client,
    newAccessTokenFromRefreshToken,
    isNewAccessTokenNeeded,
  };
}
