import classnames from 'classnames';
import preact, { h } from 'preact';
import { useMemo } from 'preact/hooks';

interface ButtonProps {
  label: string;
  customClass?: string;
  height?: 32 | 40 | 48;
  disabled?: boolean;
  type?:
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'tertiary'
    | 'tertiary-light'
    | 'tertiary-danger'
    | 'text'
    | 'text-danger';
  handleOnClick?: (e?: preact.JSX.TargetedMouseEvent<HTMLButtonElement>) => any;
  isSubmit?: boolean;
  id?: string;
  isLoading?: boolean;
  hide?: boolean;
  children?: any;
  href?: string;
}

export default function Button({
  label,
  customClass,
  height = 40,
  disabled,
  type = 'primary',
  handleOnClick,
  isSubmit = false,
  id,
  isLoading,
  hide,
  children,
  href,
}: ButtonProps) {
  const onClick = (e: preact.JSX.TargetedMouseEvent<HTMLButtonElement>) => {
    if (isLoading || !handleOnClick) return;
    handleOnClick(e);
  };

  const classNames = useMemo(
    () =>
      classnames(`rph-button rph-button-height-${height} rph-button-${type}`, {
        'rph-button-disabled': disabled,
        'rph-button-loading': isLoading,
        'rph-button-hide': hide,
        [`${customClass}`]: customClass,
      }),
    [customClass, disabled, height, hide, isLoading, type]
  );

  if (href) {
    return (
      <a href={href} target="_blank" rel="noreferrer" className={classNames} id={id}>
        {label}
      </a>
    );
  }

  return (
    <button
      disabled={disabled}
      className={classNames}
      onClick={(e) => onClick(e)}
      type={isSubmit ? 'submit' : 'button'}
      id={id}
    >
      {children}
      {isLoading ? <span className="rph-loading-circle" /> : label}
    </button>
  );
}
