import _isEmpty from 'lodash-es/isEmpty';
import ky from 'ky';
import { useEffect, useRef } from 'preact/hooks';
import structuredClone from '@ungap/structured-clone';
import { IConfig } from '.';
import { useGlobalContext, ActionType, GlobalState, UserData, User, DarkMode } from './hooks/use-global-context';
import { events, EventType } from './events';
import { VALID_ATTRIBUTE_SELECTORS } from './ExternalDomController';
import { useApi, useNear, usePasskeys, useQuestion, useRoute } from './hooks';
import { RPH_LOG_LEVELS, logger } from './utils/log';
import { LoginStep } from './Login';
import { isTokenExpired } from './utils/token';
import { QrCodeProps } from './QrCodeGenerator/component';
import { RequestFormTypes } from './Components/RequestForm/RequestForm';
import { ConnectAuthenticatorTypes } from './Components/ConnectAuthenticator/ConnectAuthenticator';
import useGoogleSignIn from './hooks/use-google-signin';
import { WalletTypes } from './Components/Profile/ProfileWallets/ProfileWallets';
import useAppleSignIn from './hooks/use-apple-signin';
import useSafeLocation from './hooks/use-window-location';
import useUserApi from './hooks/use-user-api';

let stateCopy: GlobalState | null = null;
let configCopy: IConfig;
let externalApi: ExternalApiSpec;
const internalApi: {
  google?: ReturnType<typeof useGoogleSignIn>;
  apple?: ReturnType<typeof useAppleSignIn>;
  passkeys?: ReturnType<typeof usePasskeys>;
  userApi?: ReturnType<typeof useUserApi>;
} = {};

type ExternalAPIProps = {
  config: IConfig;
  dispatchEvents: (state: GlobalState) => void;
};

type GetAccessTokenOpts = {
  waitForToken?: boolean;
  token?: string;
};

type TokenResponse = {
  access_token: string;
  refresh_token: string;
};

type RequestSignInOpts = {
  identifier?: string;
  auto_sign_in?: boolean;
  init_data?: Record<string, any>;
  user_data?: Record<string, any>;
  post_login_redirect?: string;
  login_step?: LoginStep;
  include_user_data?: boolean;
  redirect?: boolean;
  intent?: RequestSignInIntent;
  group_to_join?: string;
  request_id?: string;
} & (
  | {
      method?: never;
    }
  | {
      method: 'one_tap';
      method_options?: {
        prompt_parent_id?: string;
      };
    }
  | {
      method: 'email' | 'phone' | 'google' | 'apple' | 'passkeys' | 'anonymous';
    }
);

interface ShopifyMultipassOpts {
  return_to?: string;
}

type ManageAccountOpts = {
  visible_profile_fields?: string[];
  auto_focus_field?: string;
  post_verify_redirect?: string;
};

type ConnectionActionOpts = {
  action_type: string;
  params?: Record<string, unknown>;
  timeout?: number;
};

export type ConnectionActionResponse = {
  action_type: string;
  result: 'success' | 'error';
  messages?: string[];
  data?: Record<string, any>;
};

export enum RequestSignInIntent {
  SignUp = 'sign_up',
  SignIn = 'sign_in',
}

export enum Status {
  Failed = 'failed',
  Success = 'success',
  Loading = 'loading',
}

export enum BiometricType {
  Touch = 'touchID',
  Face = 'faceID',
}

type ConnectAuthenticatorOpts = {
  type: ConnectAuthenticatorTypes;
  status?: Status;
  biometric_type?: BiometricType;
  error?: string;
};

type RequestFieldsOpts = {
  fields: string[];
  buttonText: string;
  questionText: string;
};

type RequestFormOpts = {
  type: RequestFormTypes;
};

type SignOutOpts = {
  show_success?: boolean;
};

const ExportedLoginSteps = {
  INIT: LoginStep.INIT,
  NoAccount: LoginStep.NO_ACCOUNT,
  Success: LoginStep.SUCCESS,
  Error: LoginStep.ERROR,
  Completing: LoginStep.COMPLETING,
};

export enum SessionStorageKeys {
  FeatureFlags = 'rph_feature_flags',
}

export type ExternalApiSpec = {
  setSessionStorage: (key: SessionStorageKeys, value: string) => void;
  requestSignIn: (opts: RequestSignInOpts) => void;
  requestFields: (opts: RequestFieldsOpts) => void;
  connectAuthenticator: (opts: ConnectAuthenticatorOpts) => void;
  requestForm: (opts: RequestFormOpts) => void;
  signOut: () => void;
  getAccessToken: (opts: GetAccessTokenOpts) => Promise<string | null | undefined>;
  generateQrCode: (opts: QrCodeProps) => void;
  connectionAction: (opts: ConnectionActionOpts) => Promise<ConnectionActionResponse>;
  setLogLevel: (name: string, level: string) => void;
  localSettings: {
    setDarkMode: (mode: DarkMode) => void;
  };
  events: any;
  auth: {
    token: () => string;
    isVerifiedUser: () => boolean;
    passkeys: any;
  };
  user: {
    get: () => any;
    getValue: (key: string) => any;
    set: (data: UserData) => Promise<UserData> | undefined;
    setValue: (key: string, value: any) => Promise<UserData> | undefined;
    groups?: User['groups'];
    uploadFile: (field: string, file: File) => Promise<any>;
    manageAccount: (opts?: ManageAccountOpts) => void;
  };
  questions: {
    trigger: (questionId: string) => void;
  };
  near: {
    createNamedAccount: () => void;
    ensureImplicitAccount: () => Promise<string>;
    connectAccount: () => void;
    walletDetails: () => void;
  };
  firebase: {
    getIdToken: () => Promise<string>;
  };
  shopify: {
    generateMultipassToken: (opts: ShopifyMultipassOpts) => Promise<string>;
  };
  getAppConfig: () => GlobalState['app'] | undefined;
  LoginStep: Record<string, LoginStep>;
  version: string;
};

export type PostAuthApiSpec = {
  method: 'post' | 'put' | 'get';
  url: string;
  extra_headers: { [key: string]: string };
  timeout: number;
};

export type PostSignOutApiSpec = {
  method: 'post' | 'put';
  url: string;
  extra_headers: { [key: string]: string };
};

export type PostUserDataUpdateApiSpec = {
  method: 'post' | 'put';
  url: string;
  extra_headers: { [key: string]: string };
};

type TPostAuthApiResponse = {
  message: string;
  should_refresh_page?: boolean;
  return_to?: string;
};

type TPostSignOutApiResponse = {
  message: string;
  should_refresh_page?: boolean;
  return_to?: string;
};

function watchOnLoaded(config?: IConfig) {
  if (!('Proxy' in window)) {
    logger.warn("This browser doesn't support Proxies.");
    return;
  }

  if (!config) {
    logger.warn('OnLoaded not called with config');
    return;
  }

  const onLoadedProxy = new Proxy(config.onLoaded, {
    set(target, property, value, receiver) {
      if (property !== 'length') {
        value();
      }

      return Reflect.set(target, property, value, receiver);
    },
  });

  config.onLoaded = onLoadedProxy;
}

export default function ExternalAPI({ config, dispatchEvents }: ExternalAPIProps) {
  const { state, dispatch } = useGlobalContext();
  const { isNewAccessTokenNeeded, newAccessTokenFromRefreshToken, client: api } = useApi();
  const { navTo } = useRoute();
  const { selector: nearSelector } = useNear();
  const safeLocation = useSafeLocation();

  internalApi.google = useGoogleSignIn();
  internalApi.apple = useAppleSignIn();
  internalApi.passkeys = usePasskeys();
  internalApi.userApi = useUserApi();

  const prevAccessToken = useRef(state.auth?.access_token);

  useEffect(() => {
    // This would be a true sign in, but for now we want to fire the post authentication api
    // every time the page is loaded. This ensures that a web app has a chance to update its
    // session with user info on every page load, rather than just the first time a user signs in.
    // If we feel liek this should change, just uncomment the following line.
    // const signingIn = !prevAccessToken.current && state.auth?.access_token;
    // This token could have been loaded from the previous auth state so lets check if it's expired before proceeding.
    // If it's expired then we'll wait until a request that needs auth has triggered a refresh.
    // When the new token is loaded then we'll fire the post-auth
    if (!isTokenExpired(state.auth?.access_token as string)) {
      runPostAuthenticationSteps(config, state.auth.access_token as string).then(() => {
        // Look for any elements with the data-rownd-request-sign-in attribute. If found, they
        // should have a data-rownd-return-to attribute as well. We can redirect the browser to
        // that address at this time. (todo: What happens when multiple elements have this attribute?)
        window.document
          .querySelectorAll(
            `[${VALID_ATTRIBUTE_SELECTORS.REQUEST_SIGN_IN}], [${VALID_ATTRIBUTE_SELECTORS.REQUIRE_SIGN_IN}]`,
          )
          .forEach((el) => {
            const returnTo = el.getAttribute(VALID_ATTRIBUTE_SELECTORS.RETURN_TO);
            if (returnTo && returnTo !== window.location.pathname) {
              return safeLocation.assign(returnTo);
            }
          });
      });
    }
    const signingOut = prevAccessToken.current && !state.auth?.access_token;
    if (signingOut) {
      if (config?.postSignOutApi) {
        callPostSignOutApi(config.postSignOutApi);
      }

      if (config?.postSignOutCallback) {
        try {
          config.postSignOutCallback();
        } catch (e) {
          // No-op. This is client code that could throw errors.
        }
      }
      const signOutTriggerElements = document.querySelectorAll(`[${VALID_ATTRIBUTE_SELECTORS.SIGN_OUT_BUTTON}]`);
      if (signOutTriggerElements.length) {
        const returnToUrl = (signOutTriggerElements[0] as HTMLElement).dataset.rowndReturnTo;
        if (returnToUrl) {
          safeLocation.assign(returnToUrl);
        }
      }

      // Sign out of NEAR wallets
      if (nearSelector?.isSignedIn()) {
        nearSelector.wallet().then((wallet) => {
          wallet.signOut().catch((err: Error) => logger.error('Failed to sign out of near wallet', err.message));
        });
      }

      if (config.postSignOutRedirect) {
        safeLocation.assign(config.postSignOutRedirect);
      }
    }
    prevAccessToken.current = state.auth?.access_token;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, config?.postSignOutApi, config?.postSignOutCallback, state.auth?.access_token, prevAccessToken]);

  // Shallow clone the state so that we can modify it without affecting the original state.
  stateCopy = { ...state };
  configCopy = config;

  // Remove portions that might have functions attached, as `structuredClone` doesn't handle them.
  delete stateCopy.config;

  // Deep copy the state so external users can modify it without affecting the original state.
  stateCopy = structuredClone(stateCopy);

  useEffect(() => {
    if (config.stateListener) {
      try {
        config.stateListener({
          state,
          api: externalApi,
        });
      } catch (err) {
        logger.error('stateListener error', err);
      }
    }

    if (config.stateListeners?.length) {
      config.stateListeners.forEach((listener) => {
        try {
          listener({
            state,
            api: externalApi,
          });
        } catch (err) {
          logger.error('stateListeners error', err);
        }
      });
    }

    if (config.stateStringListener) {
      try {
        config.stateStringListener(JSON.stringify(state));
      } catch (err) {
        logger.error('stateStringListener error', err);
      }
    }
  }, [state, config]);

  async function runPostAuthenticationSteps(config: IConfig, accessToken: string) {
    try {
      dispatch({
        type: ActionType.SET_IS_WAITING_FOR_POST_AUTHENTICATION_API,
        payload: {
          waiting: true,
        },
      });

      if (config.postSignInCallback) {
        await config.postSignInCallback();
      }

      if (config.postAuthenticationApi) {
        await callPostAuthenticationApi(config.postAuthenticationApi, accessToken);
      }
    } catch (err) {
      logger.error('postAuthenticationApi error', err);
      events.dispatch(EventType.POST_AUTHENTICATION_STEPS_ERROR, { error: err });
    } finally {
      events.dispatch(EventType.POST_AUTHENTICATION_API_REQUEST_COMPLETE, {});
      dispatch({
        type: ActionType.SET_IS_WAITING_FOR_POST_AUTHENTICATION_API,
        payload: {
          waiting: false,
        },
      });
    }
  }

  async function callPostAuthenticationApi(apiSpec: PostAuthApiSpec, accessToken: string): Promise<any> {
    const { method, url, extra_headers, timeout } = apiSpec || {};
    const headers = {
      ...extra_headers,
      'Content-Type': 'application/json',
      authorization: `Bearer ${accessToken}`,
    };
    const body =
      method.toLowerCase() !== 'get'
        ? {
            access_token: accessToken,
          }
        : void 0;
    const options = {
      method,
      headers,
      body: JSON.stringify(body),
      timeout: timeout || 10000,
    };

    const response: TPostAuthApiResponse = await ky(url, options).json();
    logger.debug('postAuthenticationApi response', response);
    // ignore refresh if post_login_redirect is set
    if (!state.nav?.options?.post_login_redirect && !state.config?.postLoginUrl && response.should_refresh_page) {
      return safeLocation.reload(); // refresh the page
    }

    if (response.return_to) {
      return safeLocation.replace(response.return_to);
    }
  }

  async function callPostSignOutApi(apiSpec: PostSignOutApiSpec): Promise<any> {
    const { method, url, extra_headers } = apiSpec;
    const headers = {
      ...extra_headers,
      'Content-Type': 'application/json',
    };
    const body = {};
    const options = {
      method,
      headers,
      body: JSON.stringify(body),
    };

    try {
      const response: TPostSignOutApiResponse = await ky(url, options).json();
      logger.debug('postSignOutApi response', response);
      if (response.should_refresh_page) {
        return safeLocation.reload(); // refresh the page
      }
      if (response.return_to) {
        return safeLocation.replace(response.return_to);
      }
    } catch (err) {
      logger.error('postSignOutApi error', err);
    }
  }

  if (externalApi) {
    return null;
  }

  // This handle should only be created once
  externalApi = {
    // functions
    setSessionStorage: (key: SessionStorageKeys, value: string) => {
      window.sessionStorage.setItem(key, value);
      if (!Object.values(SessionStorageKeys).includes(key)) {
        logger.warn(`Invalid sessionStorage key: ${key}`);
      }
    },
    requestSignIn: (opts: RequestSignInOpts) => {
      const defaultOpts: RequestSignInOpts = {
        include_user_data: true,
      };
      if (opts?.login_step && !Object.values(ExportedLoginSteps).includes(opts.login_step)) {
        logger.error(`Unsupported login_step '${opts.login_step}'`);
        return;
      }

      if (opts?.intent && !Object.values(RequestSignInIntent).includes(opts.intent)) {
        logger.error(`Unsupported intent '${opts.intent}'`);
        return;
      }

      const userData = !_isEmpty(opts?.user_data)
        ? {
            ...state.user.data,
            ...opts.user_data,
          }
        : void 0;

      const userDataPromise = new Promise<void>((resolve, reject) => {
        if (!userData) {
          return resolve();
        }
        externalApi?.user
          ?.set(userData)
          ?.finally(() => {
            resolve();
          })
          .catch((err) => {
            logger.error('Failed to set user data prior to sign-in', err);
            reject(err);
          });
      });

      switch (opts?.method) {
        case 'anonymous':
        case 'email':
        case 'phone': {
          navTo('/account/login', void 0, {
            ...defaultOpts,
            ...opts,
            use_modal: true,
            sign_in_type: opts.method,
            login_step: LoginStep.INIT,
          });
          break;
        }
        case 'one_tap': {
          internalApi.google
            ?.showOneTap({
              promptParentId: opts?.method_options?.prompt_parent_id,
              userData,
              groupToJoin: opts.group_to_join,
            })
            .then((displayed: boolean) => {
              if (displayed) {
                return;
              }
              navTo('/account/login', void 0, {
                ...defaultOpts,
                ...opts,
                use_modal: true,
              });
            })
            .catch((err: Error) => {
              logger.error('Failed to show Google One Tap UI', err);
              navTo('/account/login', void 0, {
                ...defaultOpts,
                ...opts,
                use_modal: true,
              });
            });
          break;
        }
        case 'google': {
          userDataPromise.then(() => {
            internalApi.google?.authenticate({
              purpose: 'authentication',
              intent: opts?.intent,
              userData,
              groupToJoin: opts.group_to_join,
            });
          });
          break;
        }
        case 'apple': {
          userDataPromise.then(() => {
            internalApi.apple?.authenticate({
              purpose: 'authentication',
              intent: opts?.intent,
              userData,
              groupToJoin: opts.group_to_join,
            });
          });
          break;
        }
        case 'passkeys': {
          userDataPromise.then(async () => {
            try {
              await internalApi.passkeys?.authenticate({
                groupToJoin: opts.group_to_join,
              });
              navTo('/account/login', void 0, {
                use_modal: true,
                login_step: LoginStep.SUCCESS,
                sign_in_type: opts.method,
              });
            } catch (err) {
              navTo('/account/login', void 0, {
                use_modal: true,
                login_step: LoginStep.ERROR,
                sign_in_type: opts.method,
                error_message: 'Unable to verify your passkey.',
              });
              internalApi.passkeys?.eagerlyRequestAuthenticationChallenge();
            }
          });
          break;
        }
        default: {
          navTo('/account/login', void 0, {
            ...defaultOpts,
            ...opts,
            use_modal: true,
          });
        }
      }
    },
    connectAuthenticator: (opts: ConnectAuthenticatorOpts) => {
      if (!Object.values(ConnectAuthenticatorTypes).includes(opts?.type)) {
        throw new Error(`Invalid/Missing connectAuthenticator type: ${opts?.type}`);
      }
      navTo('/account/connectAuthenticator', void 0, {
        ...opts,
        use_modal: true,
      });
    },
    requestFields: (opts: RequestFieldsOpts) => {
      navTo('/account/requestFields', void 0, {
        ...opts,
        use_modal: true,
      });
    },
    requestForm: (opts: RequestFormOpts) => {
      if (!Object.values(RequestFormTypes).includes(opts?.type)) {
        throw new Error(`Invalid/Missing requestForm type: ${opts?.type}`);
      }
      navTo('/account/requestForm', void 0, {
        type: opts.type,
        use_modal: true,
      });
    },
    signOut: (opts?: SignOutOpts) => {
      dispatch({
        type: ActionType.SIGN_OUT,
        payload: {
          ...opts,
        },
      });
    },

    getAccessToken: async (opts?: GetAccessTokenOpts) => {
      const { waitForToken, token } = opts || {};

      if (token) {
        logger.log('Signing in with a 3rd party token...');
        try {
          const tokenResponse: TokenResponse = await api
            .post('hub/auth/token', {
              method: 'post',
              json: {
                app_id: stateCopy?.app?.id,
                id_token: token,
              },
            })
            .json();
          dispatch({
            type: ActionType.LOGIN_SUCCESS,
            payload: tokenResponse,
          });
        } catch (err) {
          logger.error(`Error logging in with a 3rd party token: ${(err as Error).message}`);
          throw err;
        }
      }

      let accessToken = stateCopy?.auth.access_token;

      // Wait for an access token to be available if none exists yet
      if (!accessToken && waitForToken) {
        return new Promise((resolve) => {
          logger.log('auth_wait: waiting for access token');
          const listener = (evt: any) => {
            logger.log('auth_wait: received access token');
            const data = evt.detail;
            resolve(data.access_token);
          };

          events.addEventListener(EventType.AUTH, listener, { once: true });
        });
      }

      if (isNewAccessTokenNeeded(void 0)) {
        const resp = await newAccessTokenFromRefreshToken({ ...stateCopy, config: configCopy } as GlobalState);
        accessToken = resp.access_token;
      }

      return accessToken;
    },

    generateQrCode: (opts: QrCodeProps) => {
      navTo('/qrcode', void 0, {
        use_modal: true,
        is_container_visible: true,
        ...opts,
      });
    },

    connectionAction: async <T = any,>(opts: ConnectionActionOpts) => {
      const response = await api.post('hub/connection_action', {
        authenticated: true,
        headers: {
          'Content-Type': void 0,
        },
        json: opts,
        timeout: opts.timeout,
      });

      return await response.json<T>();
    },

    setLogLevel: (name: string, level: string) => {
      const addLogLevel = (levels: Record<string, string>) => {
        window.localStorage.setItem(RPH_LOG_LEVELS, JSON.stringify({ ...levels, [`${name}`]: level }));
      };

      const stringLogLevels = window.localStorage.getItem(RPH_LOG_LEVELS);
      if (!stringLogLevels) {
        addLogLevel({});
        return;
      }

      try {
        const logLevels = JSON.parse(stringLogLevels);
        addLogLevel(logLevels);
      } catch (err) {
        logger.error('setLogLevel: ', err);
      }
    },

    localSettings: {
      setDarkMode: (mode: DarkMode) => {
        if (!Object.values(DarkMode).some((x) => x === mode)) {
          logger.error(`Unsupported dark mode '${mode}'`);
          return;
        }
        dispatch({ type: ActionType.SET_LOCAL_SETTINGS_DARK_MODE, payload: mode });
      },
    },

    events: {
      addEventListener: events.addEventListener,
      removeEventListener: events.removeEventListener,
    },

    auth: {
      token: () => {
        if (!stateCopy?.auth?.access_token) {
          throw new Error('User is not logged in.');
        }

        return stateCopy?.auth?.access_token;
      },
      isVerifiedUser: () => {
        return !!stateCopy?.auth?.is_verified_user;
      },
      passkeys: internalApi.passkeys,
    },

    user: {
      get: () => {
        return stateCopy?.user.data;
      },

      getValue: (key: string) => {
        return stateCopy?.user.data[key];
      },

      set: (data: UserData) => {
        return internalApi.userApi?.setUser(data);
      },

      setValue: (key, value) => {
        return internalApi.userApi?.setUserValue(key, value);
      },

      get groups() {
        return stateCopy?.user?.groups;
      },

      uploadFile: async (field: string, file: File): Promise<any> => {
        if (!stateCopy?.app.schema?.[field]?.type) {
          logger.error(`uploadFile: Unknown field ${field}`);
          return Promise.resolve();
        }
        if (!['image', 'document'].includes(stateCopy.app.schema?.[field].type as string)) {
          logger.error(`uploadFile: ${field} is not an image or document type. Cannot upload file.`);
          return Promise.resolve();
        }

        const formData = new FormData();
        formData.append('value', file);
        const response = await api
          .put(`me/applications/${stateCopy.app.id}/data/fields/${field}`, {
            authenticated: true,
            headers: {
              'Content-Type': void 0,
            },
            body: formData,
          })
          .json();

        dispatch({
          type: ActionType.SET_REFRESH_USER_DATA,
          payload: {
            needs_refresh: true,
          },
        });

        return response;
      },

      manageAccount: (opts?: ManageAccountOpts) => {
        navTo('/account/manage', void 0, { ...opts, use_modal: true, is_container_visible: true });
      },
    },

    questions: {
      trigger: (questionName: string) => {
        const question = stateCopy?.config?.questions.find((q) => [q.title, q.name].includes(questionName));

        if (question?.trigger.type !== 'manual') {
          throw new Error('Question trigger is not manual.');
        }

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useQuestion(question);
      },
    },

    near: {
      createNamedAccount: () => {
        navTo('/account/near/createNamedAccount', 'ExternalAPI', {
          use_modal: true,
        });
      },
      ensureImplicitAccount: async (): Promise<string> => {
        if (stateCopy?.user?.data?.near_implicit_account_id) {
          return stateCopy.user?.data?.near_implicit_account_id;
        }
        const response = await externalApi.connectionAction({
          action_type: 'near.ensure-implicit-account',
          timeout: 20000, // 20 seconds
        });
        await externalApi.user.set({
          ...stateCopy?.user?.data,
          ...response.data,
        });
        return stateCopy?.user?.data?.near_implicit_account_id;
      },
      walletDetails: () => {
        navTo('/account/walletDetails', 'ExternalAPI', {
          use_modal: true,
          type: WalletTypes.Near,
          wallet: {
            account_id: stateCopy?.user?.data?.near_implicit_account_id as string,
            private_key: stateCopy?.user?.data?.near_implicit_private_key as string,
            seed_phrase: stateCopy?.user?.data?.near_implicit_seed_phrase as string,
            field_name: 'near_implicit_account_id',
            display_name: stateCopy?.app?.schema?.near_implicit_account_id?.display_name,
          },
        });
      },
      connectAccount: () => {
        navTo('/account/login', 'ExternalApi', {
          use_modal: true,
          login_step: LoginStep.CHOOSE_WALLET_PROVIDER,
          wallet_provider_scopes: ['near'],
        });
      },
    },

    firebase: {
      getIdToken: async () => {
        const response = await externalApi.connectionAction({
          action_type: 'firebase-auth.get-firebase-token',
          timeout: 20000, // 20 seconds
        });

        if (!response.data?.token) {
          throw new Error('Failed to retrieve token.');
        }

        return response.data.token as string;
      },
    },

    shopify: {
      generateMultipassToken: async (opts: ShopifyMultipassOpts): Promise<string> => {
        const response = await externalApi.connectionAction({
          action_type: 'shopify.generate-multipass-token',
          params: {
            return_to: opts.return_to,
          },
          timeout: 20000, // 20 seconds
        });

        return response.data?.token;
      }
    },

    getAppConfig: () => {
      return stateCopy?.app;
    },

    LoginStep: ExportedLoginSteps,
    version: `${process?.env?.CF_PAGES_COMMIT_SHA?.substring(0, 7) || 'unknown'} (${
      process?.env?.CF_PAGES_BRANCH || 'unknown'
    })`,
  };

  if (!window.rph) {
    window.rph = window.rownd = externalApi;
    state.config?.onLoaded.forEach((cb) => cb());

    watchOnLoaded(state.config);

    // Dispatch events
    dispatchEvents(stateCopy);
  }

  return null;
}
