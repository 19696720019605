import { isAfter, isBefore, isValid, parseISO } from 'date-fns';
import { REDACTED } from '../utils';
import * as Types from './types';
import { automationLogger } from './utils';

type ConditionEvaluator = (data: Record<string, any>, attribute: string, value?: any) => boolean;
type RuleEvaluator = (opts: RuleEvaluatorOptions) => [boolean, string];
interface RuleEvaluatorOptions {
  userData: Record<string, any>;
  metadata: Types.UserMetadata;
  rule: Types.AutomationRule;
}

function evaluateRule(obj: Record<string, any>, rule: Types.AutomationRule): [boolean, string] {
  const conditionEvalFn = conditionEvaluators[rule.condition?.toUpperCase() as Types.Condition];
  if (!conditionEvalFn) {
    const msg = 'Unknown condition';
    automationLogger.error(msg, rule.condition);
    return [false, msg];
  }
  const result = conditionEvalFn(obj, rule.attribute, rule.value);
  return [
    result,
    result
      ? `Condition passed: data.${rule.attribute} ${rule.condition} ${result}`
      : `Condition failed: '${rule.entity_type}.${rule.attribute}' ${
          rule.condition
        } ${rule.value?.toString()} (was ${result})`,
  ];
}

// Automation evaluators for each entity type.
export const ruleEvaluators: Record<Types.EntityType, RuleEvaluator> = {
  [Types.EntityType.USER_DATA]: (opts: RuleEvaluatorOptions) => {
    const { userData, rule } = opts;
    return evaluateRule(userData, rule);
  },

  [Types.EntityType.METADATA]: (opts: RuleEvaluatorOptions) => {
    const { metadata, rule } = opts;
    return evaluateRule(metadata, rule);
  },
  [Types.EntityType.SCOPE]: (opts: RuleEvaluatorOptions) => {
    const { rule } = opts;

    if (rule.attribute === 'url') {
      return ruleUrlEvaluator(rule);
    }

    return [
      false,
      `Condition failed (incorrect attribute type for scope):  '${rule.entity_type}.${
        rule.attribute
      }' ${rule.value?.toString()}`,
    ];
  },
};

export const ruleUrlEvaluator = (rule: Types.AutomationRule): [boolean, string] => {
  const value = rule.value;
  if (!value) {
    return [false, `Condition failed: Missing value for ruleUrlEvaluator`];
  }

  const condition = rule.condition;
  if (condition === Types.Condition.EQUALS) {
    return doesUrlMatch(rule, value);
  }

  // Evaluate the entire URL for conditions like CONTAINS page=1 for https://example.com?page=1
  return evaluateRule({ url: window.location.href }, rule);
};

const doesUrlMatch = (rule: Types.AutomationRule, value: string): [boolean, string] => {
  const url = new URL(value, window.origin);
  const actualPaths = window.location.pathname.split('/');
  const paths = url.pathname.split('/');

  const successMessage = `Condition passed: data.${rule.attribute} ${rule.condition}`;
  const failedMessage = `Condition failed: '${rule.entity_type}.${rule.attribute}' ${
    rule.condition
  } ${rule.value?.toString()}`;

  for (let index = 1; index < Math.max(paths.length, actualPaths.length); index++) {
    const path = paths?.[index];
    const actualPath = actualPaths?.[index];
    if (path === '**') {
      return [true, successMessage];
    }

    if (!path && !actualPath) {
      continue;
    }

    if (path === undefined || actualPath === undefined) {
      return [false, failedMessage];
    }

    if (path === '*' || path === actualPath) {
      continue;
    }

    return [false, failedMessage];
  }

  const doesOriginMatch = url.origin === window.location.origin;

  return [doesOriginMatch, doesOriginMatch ? successMessage : failedMessage];
};

// Condition evaluators that check a data object (user_data or metadata) with variouis strategies
// mapping to each rule condition type.
const conditionEvaluators: Record<Types.Condition, ConditionEvaluator> = {
  [Types.Condition.EQUALS]: (data, attribute, value) => {
    return data[attribute] === value;
  },
  [Types.Condition.NOT_EQUALS]: (data, attribute, value) => {
    return data[attribute] !== value;
  },
  [Types.Condition.CONTAINS]: (data, attribute, value) => {
    return data[attribute]?.includes(value);
  },
  [Types.Condition.NOT_CONTAINS]: (data, attribute, value) => {
    return !data[attribute]?.includes(value);
  },
  [Types.Condition.IN]: (data, attribute, value?: string[]) => {
    if (!value || !value?.length) {
      return false;
    }
    return value?.includes(data[attribute]);
  },
  [Types.Condition.NOT_IN]: (data, attribute, value?: string[]) => {
    if (!value || !value?.length) {
      return false;
    }
    return !value?.includes(data[attribute]);
  },
  [Types.Condition.EXISTS]: (data, attribute) => {
    return ![null, REDACTED, undefined].includes(data[attribute]);
  },
  [Types.Condition.NOT_EXISTS]: (data, attribute) => {
    return [null, REDACTED, undefined].includes(data[attribute]);
  },
  [Types.Condition.DATE_IS_BEFORE]: (data, attribute, value) => {
    // True if field is blank or date in field is before the value
    const conditionDate = parseISO(value);
    const dataDate = parseISO(data[attribute]);

    return (
      [null, REDACTED, undefined].includes(data[attribute]) || !isValid(dataDate) || isBefore(dataDate, conditionDate)
    );
  },
  [Types.Condition.DATE_IS_AFTER]: (data, attribute, value) => {
    // True if field is blank or date in field is after the value
    const conditionDate = parseISO(value);
    const dataDate = parseISO(data[attribute]);

    return (
      [null, REDACTED, undefined].includes(data[attribute]) || !isValid(dataDate) || isAfter(dataDate, conditionDate)
    );
  },
  [Types.Condition.GREATER_THAN]: (data, attribute, value) => {
    if (data[attribute] === undefined) return false;
    const attributeValue = Number(data[attribute]);
    return attributeValue > Number(value);
  },
  [Types.Condition.GREATER_THAN_EQUAL]: (data, attribute, value) => {
    if (data[attribute] === undefined) return false;
    const attributeValue = Number(data[attribute]);
    return attributeValue >= Number(value);
  },
  [Types.Condition.LESS_THAN]: (data, attribute, value) => {
    if (data[attribute] === undefined) return false;
    const attributeValue = Number(data[attribute]);
    return attributeValue < Number(value);
  },
  [Types.Condition.LESS_THAN_EQUAL]: (data, attribute, value) => {
    if (data[attribute] === undefined) return false;
    const attributeValue = Number(data[attribute]);
    return attributeValue <= Number(value);
  },
};
